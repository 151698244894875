@import "./mixins.scss";

.header {
  position: fixed;
  padding-top: 10px;
  padding-bottom: 7px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  background-color: rgba($color: #000000, $alpha: 0.9);

  @include media-breakpoint-range(tab-landscape-up) {
    padding-top: 40px;
    background-color: transparent;
    padding-bottom: 0;
  }

  .logo {
    max-width: 150px;
  }

  nav {
    justify-content: space-between;
    font-family: $font-primary;
    align-items: center;
    padding: 0 20px;

    @include media-breakpoint-range(tab-landscape-up) {
      padding: 0;
      align-items: flex-start;
    }

    .close {
      @extend .menu-toggle;
      margin-left: auto;
    }

    .menu-toggle {
      background-color: transparent;
      border: none;
      display: block;

      @include media-breakpoint-range(tab-landscape-up) {
        display: none;
      }
    }

    ul {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.36s ease;
      background-color: rgba($color: #000000, $alpha: 0.75);
      backdrop-filter: blur(20px);
      padding: 40px;
      &.active {
        visibility: visible;
        opacity: 1;
      }

      @include media-breakpoint-range(tab-landscape-up) {
        background-color: transparent;
        display: block;
        position: relative;
        width: auto;
        padding: 0;
        opacity: 1;
        visibility: visible;
      }

      list-style: none;
      margin: 0;
      li {
        display: block;
        margin: 40px 0;

        @include media-breakpoint-range(tab-landscape-up) {
          margin: 0;
          display: inline-block;
          vertical-align: middle;
        }
        a {
          display: block;
          padding: 0px 16px;
          font-size: 18px;
          text-transform: uppercase;
          position: relative;
          transition: color 0.3s ease;

          &:hover {
            color: $primary-text;
          }

          &.active {
            color: white;
            @include media-breakpoint-range(tab-landscape-up) {
              color: $primary-text;
            }
            &::after {
              content: "";
              position: absolute;
              display: block;
              width: calc(100% - 32px);
              height: 3px;
              bottom: -10px;
              left: 50%;
              margin-left: calc((-100% + 32px) / 2);
              background-color: $primary-color;
            }
          }
        }
        &:last-of-type {
          a {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.inner-pages {
  .header {
    background-color: white;
    @include box-shadow(level-1);
    padding-top: 20px;
    padding-bottom: 20px;

    nav {
      align-items: center;
    }
  }
}
