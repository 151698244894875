html {
  font-size: 16px;
  scroll-behavior: smooth;
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-secondary;
  font-weight: 400;
  color: darken($color: $secondary-color, $amount: 25);

  a {
    color: $primary-color;
    font-size: 16px;
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  letter-spacing: 1px;
}

p {
  letter-spacing: 0.5px;
}

.container {
  width: 100%;
  margin: 0 auto;
  @include media-breakpoint-range(tab-landscape-up) {
    max-width: calc(100% - 80px);
  }

  &.restrict {
    max-width: 1170px;
  }
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.text-light {
  font-weight: 300;
}
