.button {
  min-width: 120px;
  padding: 0px 20px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  border: 1px solid;
  border-radius: 4px;
  letter-spacing: 0.5px;
  transition: all 0.36s ease;
  text-transform: uppercase;
  cursor: pointer;
  
  &.primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: white;

    &:hover {
      background-color: darken($color: $primary-color, $amount: 10);
      border-color: darken($color: $primary-color, $amount: 10);
    }
  }

  &.primary-line {
    color: $primary-color;
    border-color: $primary-color;
    background-color: transparent;

    &:hover {
      background-color: rgba($color: $primary-color, $alpha: .1);
    }
  }
}